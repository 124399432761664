import { render, staticRenderFns } from "./trainingSite.vue?vue&type=template&id=1984962e&scoped=true"
import script from "./trainingSite.vue?vue&type=script&lang=js"
export * from "./trainingSite.vue?vue&type=script&lang=js"
import style0 from "./trainingSite.vue?vue&type=style&index=0&id=1984962e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1984962e",
  null
  
)

export default component.exports